import { InMemoryCache, makeVar } from "@apollo/client";

const cartInitialValue = {
  items: []
};

const uiInitialValue = {
  sideBarOpen: false
};

export const cartVar = makeVar(cartInitialValue);

export const currencyVar = makeVar('USD');

export const uiVar = makeVar(uiInitialValue);

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        ui: {
          read() {
            return uiVar();
          }
        },
        cart: {
          read() {
            return cartVar();
          }
        },
        selectedCurrency: {
          read() {
            return currencyVar();
          }
        }
      }
    }
  }
});
