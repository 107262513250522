import { shouldPolyfill } from '@formatjs/intl-numberformat/should-polyfill';

async function polyfill(locale) {
  if (shouldPolyfill()) {
    await import('@formatjs/intl-numberformat/polyfill');
  }
  if (Intl.NumberFormat.polyfilled) {
    switch (locale) {
      default:
        await import('@formatjs/intl-numberformat/locale-data/en');
        break;
      case 'fr':
        await import('@formatjs/intl-numberformat/locale-data/fr');
        break;
    }
  }
}

export default polyfill;