import { preset } from "@rebass/preset";

export const globalStyles = {
  a: {
    cursor: "pointer",
  },
  button: {
    "&:hover": {
      cursor: "pointer",
    },
    "&:disabled": {
      cursor: "not-allowed",
    },
  },
  ul: {
    listStyleType: "none",
  },
};

const theme = {
  ...preset,
  breakpoints: [ '40em', '48em', '64em' ],
  colors: {
    ...preset.colors,
    background: '#F5F5F4',
    primary: "#4B5548",
    green900: "#2B2E2B",
  },
  forms: {
    select: {
      backgroundColor: 'white',
      backgroundPosition: '100% 60%',
      borderColor: 'white',
      borderRadius: 'none',
      padding: '8px 13px 5px 10px;',
      fontSize: '14px'
    },
  },
  buttons: {
    ...preset.buttons,
    outline:  {
      ...preset.buttons.outline,
      border: '1px solid #2B2E2B',
      color: '#2B2E2B',
      boxShadow: 'none'
    },
    primary: {
      bg: "#4B5548",
      borderRadius: 0
    },
  },
};
export default theme;
