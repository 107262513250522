import { cartVar } from "../graphql/cache";

export function useCart() {
  const addToCart = (id) => {
    const cart = cartVar();
    const cartItems = cart?.items ?? [];

    const itemIndex = cartItems.findIndex(({ id: itemId }) => itemId === id);
    let updatedItems = [...cartItems];
    if (itemIndex > -1) {
      const item = cartItems[itemIndex];
      updatedItems[itemIndex] = { ...item, quantity: item.quantity + 1 }
    } else {
      updatedItems.push({ id, quantity: 1 });
    };
    cartVar({ ...cart, items: updatedItems })
  }

  const deleteFromCart = (id) => {
    const cart = cartVar();
    const updatedItems = cart?.items?.filter(({ id: itemId }) => itemId !== id) ?? [];

    cartVar({ ...cart, items: updatedItems })
  }

  const removeUnitFromCart = (id) => {
    const cart = cartVar();
    const cartItems = cart?.items ?? [];

    const itemIndex = cartItems.findIndex(({ id: itemId }) => itemId === id);
    const item = cartItems[itemIndex];
    if (!item) return;

    const updatedItems = [...cartItems];
    if (item.quantity > 1) {
      updatedItems.splice(itemIndex, 1, { ...item, quantity: item.quantity - 1 });
    } else {
      updatedItems.splice(itemIndex, 1);
    }
    cartVar({ ...cart, items: updatedItems })
  }

  return {
    addToCart, deleteFromCart, removeUnitFromCart
  }
}