import { Select } from '@rebass/forms';
import { Box, Button, Flex, Image, Text } from "rebass";
import { css } from "@emotion/core";
import CartItem from "../cart-item";
import arrowRight from "../../../assets/icons/arrow-right.svg";
import { useQuery } from "@apollo/client";
import { GET_CART } from "../../../graphql/queries/cart";
import { GET_UI } from "../../../graphql/queries/ui";
import { currencyVar, uiVar } from "../../../graphql/cache";
import { useMemo } from "react";
import { GET_CURRENCY } from '../../../graphql/queries/currency';
import { useCurrencyFormat } from '../../../hooks/useCurrencyFormat';

const overlay = css`
  opacity: 0.8;
  backdrop-filter: blur(3px);
  background-color: #CDD1CE;
  height: "100%";
  z-index: 1;
  transition: 0.5s;`;

const sideContainer = css`
  background-color: #F2F2EF;
  color: #1E2D2B;
  height: 100vh;
  z-index: 2;
  transition: 0.2s;`;

const arrowBorder = css`
  border-radius: 50%;
  border: 1px solid rgb(198, 204, 199);
  cursor: pointer;
`;

const cartFooter = css`
  border-top: 1px solid #d0d0d0;
  box-shadow: 0 -4px 12px rgb(0 0 0 / 15%);
  z-index: 1;
`;

const SideBar = () => {
  const { formatCurrency } = useCurrencyFormat(currencyVar());
  const { loading: cLoading, error: cError, data: { cart, products } = {} } = useQuery(GET_CART,
    {
      variables: { currency: currencyVar() },
      fetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-first'
    });
  const { data: { currency = [] } = {} } = useQuery(GET_CURRENCY);
  const { data: { ui } } = useQuery(GET_UI);

  const handleSideBarClose = () => {
    const ui = uiVar();
    uiVar({ ...ui, sideBarOpen: false });
  }

  const handleCurrencyChange = (e) => {
    currencyVar(e.target.value);
  }

  const cartItemTotal = useMemo(() => {
    if (products?.length && cart?.items?.length) {
      const total = cart.items.reduce((acc, item) => {
        const product = products.find((p) => p.id === item.id);
        if (product) return (product.price * item.quantity) + acc;
        return acc;
      }, 0);
      return total;
    }
    return 0;
  }, [cart?.items, products]);

  if ((cLoading && !cart) || (cError && !cart) || !cart) return null;

  return (
    <>
      <Box
        width={ui?.sideBarOpen ? "100%" : "0"}
        sx={{
          position: "fixed",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
        css={overlay}
      />
      <Flex
        flexDirection="column"
        width={ui?.sideBarOpen ? "100%" : "0"}
        maxWidth="550px"
        sx={{
          position: "fixed",
          top: 0,
          right: 0,
        }}
        css={sideContainer}
      >
        <Flex
          px="3"
          justifyContent="space-between"
          alignItems="center"
          minHeight="50px"
        >
          <Flex
            onClick={handleSideBarClose}
            css={arrowBorder}
            width="24px"
            height="24px"
            alignItems="center"
            justifyContent="center"
          >
            <Image width="10px" height="15px" src={arrowRight} />
          </Flex>
          <Text as="h5" color="#696969" fontSize="10px" letterSpacing="1px">
            YOUR CART
          </Text>
          <Box />
        </Flex>
        <Text mx="3" color="red">{cError}</Text>
        <Flex px="3" mb="2">
          <Select
            defaultValue={currencyVar()}
            onChange={handleCurrencyChange}
            id="currency"
            name="currency"
            minWidth="80px">
            {currency.map((cur, i) => <option key={`${i}-${cur}`} value={cur}>{cur}</option>)}
          </Select>
        </Flex>
        <Box px="3" flex="1" overflowY="auto">
          {products?.length && cart?.items?.map((item) => {
            const product = products.find((product) => product.id === item.id);
            return <CartItem key={`${item.id}-${item.quantity}`} id={product.id} imageUrl={product.image_url} price={product.price} quantity={item.quantity} title={product.title} />
          })}
        </Box>
        <Box css={cartFooter} p="3">
          <Flex justifyContent="space-between" mb="4">
            <Text fontSize="12px">Subtotal</Text>
            <Text fontWeight="bold"> {formatCurrency(cartItemTotal)}</Text>
          </Flex>
          <Box>
            <Button
              display="block"
              mb="3"
              px="2"
              py="3"
              width="100%"
              variant="outline"
              mr={2}
              fontSize="14px"
            >
              MAKE THIS A SUBSCRIPTION (SAVE 20%)
            </Button>
            <Button
              display="block"
              px="2"
              py="3"
              width="100%"
              variant="primary"
              mr={2}
              fontSize="14px"
            >
              PROCEED TO CHECKOUT
            </Button>
          </Box>
        </Box>
      </Flex>
    </>
  );
};
export default SideBar;
