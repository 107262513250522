import { ApolloClient, ApolloProvider } from "@apollo/client";
import { Global } from "@emotion/core";
import { ThemeProvider } from "emotion-theming";
import Products from "./components/products";
import SideBar from "./components/common/sidebar";
import theme, { globalStyles } from "./config/theme";
import { cache } from "./graphql/cache";

const client = new ApolloClient({
  uri: "https://pangaea-interviews.now.sh/api/graphql",
  cache,
});

function App() {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Global styles={globalStyles} />
        <SideBar/>
        <Products/>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
