import { gql } from "@apollo/client";

export const GET_PRODUCTS = gql`
query products($currency: Currency!) {
  products {
    id
    title
    image_url
    price(currency: $currency)
  }
  selectedCurrency @client
}`