import { useQuery } from "@apollo/client";
import { Box, Button, Flex, Heading, Image, Text } from "rebass";
import { currencyVar, uiVar } from "../graphql/cache";
import { GET_PRODUCTS } from "../graphql/queries/products";
import { useCart } from "../hooks/useCart";
import { useCurrencyFormat } from "../hooks/useCurrencyFormat";

const Products = () => {
  const { formatCurrency } = useCurrencyFormat(currencyVar());
  const { loading, error, data } = useQuery(GET_PRODUCTS,
    {
      variables: { currency: currencyVar() },
      partialRefetch: true, fetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-first'
    }
  );
  const { addToCart } = useCart();

  if (loading && !data) return <p>Loading...</p>;
  if (error) return <p>Error :( ${error.message}</p>;
  if (!data) return <p>No data!</p>;

  const handleAddToCart = (id) => {
    const ui = uiVar();
    uiVar({ ...ui, sideBarOpen: true });
    addToCart(id);
  }

  return (
    <Box>
      <Heading>
        Products
      </Heading>
      <Flex mx="auto" maxWidth="1300px" flexWrap="wrap">
        {data.products.map(({ id, price, title, image_url }) => (
          <Flex
            key={`${id}-${title}`}
            flexDirection="column"
            width={[1 / 2, 1 / 2, 1 / 3]}
            px={5}
            py={3}
            justifyContent="flex-end"
            alignItems="center"
            textAlign="center"
          >
            <Box mb="2" objectFit="contain">
              <Image
                maxHeight="170px"
                mb="3"
                src={image_url}
              />
              <Text fontSize="0.85rem">{title}</Text>
            </Box>
            <Box mb="2" fontSize="0.95rem">
              <Text as="p" display="inline">
                From:
              </Text>{" "}
              <Text as="p" display="inline">
                {formatCurrency(price)}
              </Text>
            </Box>
            <Button
              px="2"
              py="3"
              width="100%"
              maxWidth="157px"
              variant="primary"
              mr={2}
              onClick={() => handleAddToCart(id)}
            >
              Add to cart
            </Button>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};

export default Products;
