import { gql } from "@apollo/client";

export const GET_CART = gql`
query cart($currency: Currency!) {
  products {
    id
    title
    image_url
    price(currency: $currency)
  }
  cart @client { 
    items {
      id
      quantity
    }
  }
  selectedCurrency @client
}`