import { Box, Flex, Image, Text } from "rebass";
import { css } from "@emotion/core";
import { useCart } from "../../hooks/useCart";
import { currencyVar } from "../../graphql/cache";
import { useCurrencyFormat } from "../../hooks/useCurrencyFormat";

const priceSelector = css`border: .5px solid #BCBCBC;`;

const CartItem = ({ id, imageUrl, price, quantity, title }) => {
  const { formatCurrency } = useCurrencyFormat(currencyVar());
  const { addToCart, deleteFromCart, removeUnitFromCart } = useCart();

  return (
    <Flex mb="3" minHeight="120px" p="3" bg="white" sx={{ position: "relative" }}>
      <Flex flexDirection="column" pr="2" mr="4" flexBasis="65%" justifyContent="space-between">
        <Text as="h5">{title}</Text>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex css={priceSelector} p="2" minWidth="76px" justifyContent="space-between">
            <Text onClick={() => removeUnitFromCart(id)} sx={{ cursor: 'pointer' }}>-</Text>
            <Text>{quantity}</Text>
            <Text onClick={() => addToCart(id)} sx={{ cursor: 'pointer' }}>+</Text>
          </Flex>
          <Text fontSize="14px">
            {formatCurrency(price * Number(quantity))}
          </Text>
        </Flex>
      </Flex>
      <Flex flexBasis="30%" justifyContent="center" objectFit="contain" alignSelf="center"><Image height="80px" src={imageUrl} /></Flex>
      <Box onClick={() => deleteFromCart(id)} sx={{ position: "absolute", top: "0", right: "0", cursor: "pointer" }} p="1" mr="2" color="gray700" fontWeight="500">x</Box>
    </Flex>
  )
}

export default CartItem;
