export function useCurrencyFormat(currency) {
  const formatCurrency = (value) =>
    new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency
    }).format(value);

  return {
    formatCurrency
  }
}